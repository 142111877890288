/* Styles */
import '@/scss/main.scss';
import '@mdi/font/css/materialdesignicons.css';
import 'bulma-extensions/dist/css/bulma-extensions.min.css';
import 'vue-toast-notification/dist/theme-default.css';
import 'vue-json-pretty/lib/styles.css';

/* Core */
import Vue from 'vue';
import Buefy from 'buefy';
import VueMoment from 'vue-moment';
import VueToast from 'vue-toast-notification';
import VueAuthenticate from 'vue-authenticate';
import VueClipboard from 'vue-clipboard2';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueJsonPretty from 'vue-json-pretty';

/* Router & Store */
import router from './router';
import store from './store';

/* Vue. Main component */
import App from './App.vue';

/* Vue. Component in recursion */
import AsideMenuList from './components/AsideMenuList.vue';

Vue.config.productionTip = false;

/* These components are used in recursion algorithm */
Vue.component('AsideMenuList', AsideMenuList);
Vue.component('vue-json-pretty', VueJsonPretty);
Vue.use(VueMoment);
Vue.use(Buefy);
Vue.use(VueToast, { position: 'top-right' });
Vue.use(VueClipboard);
Vue.use(VueAxios, axios);
Vue.use(VueAuthenticate, {
  tokenType: 'Bearer',
  loginUrl: '/v1/auth/login',
  providers: {
    google: {
      url: 'v1/auth/google/callback',
      clientId: '886732002208-l43ej0671h02831pr18odf724n8mpaup.apps.googleusercontent.com',
    },
  },
  bindRequestInterceptor() {
    this.$http.interceptors.request.use((inConfig) => {
      const config = inConfig;
      if (this.isAuthenticated()) {
        if (config.url.startsWith('api')) {
          // if (location.hostname === 'localhost') {
          config.baseURL = 'https://devproduction.hardwario.com';
          //config.baseURL = 'http://localhost:4000';
          // }
        } else {
          config.baseURL = 'https://api.hardwario.cloud';
        }
        config.headers.Authorization = [this.options.tokenType, this.getToken()].join(' ');
      } else {
        config.baseURL = 'https://api.hardwario.cloud';
        delete config.headers.Authorization;
      }
      return config;
    });
  },
});

Vue.prototype.$http.interceptors.response.use((response) => response, (error) => {
  if (error && error.response) {
    try {
      console.log(error.response);
      const msg = error.response.data.message ? error.response.data.message : error.response.data;
      Vue.$toast.error(msg.replace('body.', ''));
    } catch (e) {
      Vue.$toast.error(error.toString());
    }

    if (error.response.status === 401) {
      Vue.prototype.$auth.logout();
      router.push({ name: 'login' });
    }
  }
  return Promise.reject(error);
});

router.beforeEach((to, from, next) => {
  const authenticated = Vue.prototype.$auth.isAuthenticated();
  if ((to.path !== '/login' && to.path !== 'login') && !authenticated) {
    if (to.path !== '/batches') {
      localStorage.setItem('redirectPath', to.fullPath);
    }
    next({ path: '/login' });
  } else if ((to.path === '/login' || to.path === 'login') && authenticated) {
    next({ path: '/' });
  } else {
    next();
  }
});

/* Collapse mobile aside menu on route change */
router.afterEach(() => {
  store.commit('asideMobileStateToggle', false);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#main');
