<template>
  <div>
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  created() {
    const payload = this.$auth.getPayload();
    if (payload) this.$store.commit('user', payload);
  },
};
</script>
