<template>
  <div id="app" >
    <nav-bar/>
    <aside-menu :menu="menu"/>
    <router-view class="flex" />
    <footer-bar/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NavBar from '../components/NavBar.vue';
import AsideMenu from '../components/AsideMenu.vue';
import FooterBar from '../components/FooterBar.vue';

export default {
  name: 'app',
  components: {
    FooterBar,
    AsideMenu,
    NavBar,
  },
  data() {
    return {
      menuItems: [
        'General',
        [
          {
            to: '/batches',
            icon: 'table',
            label: 'Batches',
          },
          {
            to: '/inventory',
            icon: 'table',
            label: 'Inventory',
          },
          {
            to: '/product/family',
            icon: 'table',
            label: 'Product family',
          },
          {
            to: '/device-info',
            icon: 'table',
            label: 'Device info',
          },
        ],
        'Log',
        [

          {
            to: { name: 'testerLogs' },
            icon: 'table',
            label: 'Tester',
          },
          {
            to: { name: 'LogProvisioning' },
            icon: 'table',
            label: 'Provisioning',
          },
        ],
      ],
    };
  },
  computed: {
    ...mapState([
      'userIsAdmin',
    ]),
    menu() {
      return this.menuItems.map((key) => {
        if (!Array.isArray(key)) {
          return key;
        }
        return key.filter((item) => {
          if (item.onlyAdmin && !this.userIsAdmin) {
            return false;
          }
          return true;
        });
      });
    },
  },
  methods: {
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => this.$route.path.indexOf(path) === 0);
    },
  },
};
</script>
