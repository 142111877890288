import Vue from 'vue';
import Router from 'vue-router';
import Base from './views/Base.vue';

Vue.use(Router);

export default new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "Login" */ './views/Login.vue'),
    },
    {
      path: '/',
      component: Base,
      redirect: {
        name: 'batches',
      },
      children: [
        {
          path: 'batches',
          name: 'batches',
          component: () => import(/* webpackChunkName: "Batches" */ './views/Batches.vue'),
        },
        {
          path: 'batch-add',
          name: 'batch-add',
          component: () => import(/* webpackChunkName: "Batche" */ './views/Batch.vue'),
        },
        {
          path: 'batch/:id',
          name: 'batch',
          component: () => import(/* webpackChunkName: "Batche" */ './views/Batch.vue'),
        },
        {
          path: 'inventory',
          name: 'inventory',
          component: () => import(/* webpackChunkName: "Inventory" */ './views/Inventory.vue'),
        },
        {
          path: 'log/tester',
          name: 'testerLogs',
          component: () => import(/* webpackChunkName: "TesterLogs" */ './views/TesterLogs.vue'),
        },
        {
          path: 'log/provisioning',
          name: 'LogProvisioning',
          component: () => import(/* webpackChunkName: "LogProvisioning" */ './views/LogProvisioning.vue'),
        },
        {
          path: 'product/family',
          name: 'product-family',
          component: () => import(/* webpackChunkName: "ProductFamily" */ './views/ProductFamily.vue'),
        },
        {
          path: 'device-info',
          name: 'device-info',
          component: () => import(/* webpackChunkName: "DeviceInfo" */ './views/DeviceInfo.vue'),
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});
